/**
 * Configure quotes UI Javascript
 *
 * @param config
 */
function configure(config)
{
	//console.log('quotes configured');
	//console.log(config);

	// Listen for the "Accept Quote" button to be clicked
	$('#btn_accept').click(function(e) {
		e.preventDefault();

		$('#btn_accept').prop('disabled', true);
		$('#btn_reject').prop('disabled', true);

		if (config.acceptCallback)
		{
			let args = [];

			_executeFunctionByName(config.acceptCallback, window, args);
		}
		else
		{
			// ... no special callback, just submit the form

			$('#myform_accept').submit();
		}
	});
}

/**
 * Execute a function, where you have the name of the function in a string
 *
 * @param functionName
 * @param context
 * @returns {*}
 * @private
 */
function _executeFunctionByName(functionName, context /*, args */)
{
	var args = Array.prototype.slice.call(arguments, 2);
	var namespaces = functionName.split(".");
	var func = namespaces.pop();

	for (var i = 0; i < namespaces.length; i++)
	{
		context = context[namespaces[i]];
	}

	return context[func].apply(context, args);
}

// This tells Webpack to export these functions so they are
module.exports = {

	// what you want to call it externally : what the function name is internally
	configure: configure
}

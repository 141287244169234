// We need a specific version of jquery till we update bootstrap
import $ from 'coc-jquery';

// This imports it _for use in this file_
//import Example from './example.js';

// This makes this specific Example module available everywhere else in the DOM
window.Example = require('.././example.js');

window.jQuery = $;
window.$ = $;

// CSS
import '../../assets/coc1/css/base.css';

// Other ChargeOver-specific stuff
window.ChargeOver = window.ChargeOver || {};

window.ChargeOver.CoC = window.ChargeOver.CoC || {};

// Gateway stuff
window.ChargeOver.CoC.Stripe = require('./stripe.js');

// Validation for jQuery ValidationEngine
window.ChargeOver.CoC.Validation = {};
window.ChargeOver.CoC.Validation.Ach = require('./validation/ach.js');

// Signup page stuff
window.ChargeOver.CoC.Signup = {};
window.ChargeOver.CoC.Signup.Shared = require('./signup/shared.js');

// UI stuff
window.ChargeOver.CoC.UI = {};
window.ChargeOver.CoC.UI.Quote = require('./ui/quote.js');

// Integrations
window.ChargeOver.CoC.Integrations = {};
window.ChargeOver.CoC.Integrations.Docusign = require('./integrations/docusign.js');

import 'jquery-validation/dist/jquery.validate';

require('jquery-ui');

const sentryTracking = require('./sentry-tracking.js')

if (typeof jQuery != 'undefined')
{
	// jQuery is loaded => print the version
	console.log('jq: ' + jQuery.fn.jquery);
}

$(document).ready(function() {

	sentryTracking.configureSentry();

	let config_from_footer = JSON.parse($('#CHARGEOVER_COC_CONFIG').val());

	if (config_from_footer.config.development)
	{
		console.log('Configuring ChargeOver (page)...');
		console.log(config_from_footer);
	}

	window.ChargeOver.CoC.Stripe.configure();

	// Validation
	window.ChargeOver.CoC.Validation.Ach.configure();

	// UI
	window.ChargeOver.CoC.UI.Quote.configure(config_from_footer.ui.quote);

	// Integrations
	window.ChargeOver.CoC.Integrations.Docusign.configure(config_from_footer.integrations.docusign);

	// Hosted sign-up pages
	window.ChargeOver.CoC.Signup.Shared.configure(config_from_footer.signup.shared);

});

// Configure sentryOnLoad
const $ = require("coc-jquery");

function configureSentry(){
	window.sentryOnLoad = function () {
		const config = JSON.parse($('#CHARGEOVER_COC_CONFIG').val());
		const development = config.config.development;

		Sentry.init({
			environment: development ? 'development' : 'production',
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 0
		});

		// Set user context
		Sentry.setUser({ip_address: "{{auto}}", email: null});

	};
}


module.exports = {
	configureSentry: configureSentry,
}

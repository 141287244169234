
function configure(config)
{

	// Show processing fees on hosted sign-up forms
	if ($('body.controller_cocsignup.stage1_route')[0])
	{

		// When the payment method changes
		$('input[name="paymethod"]').change(function () {
			_showProcessingFee(config);
		});

		// We also trigger the processing fees when the billing state changes
		$('.handle_signupBillState').change(function() {
			_showProcessingFee(config);
		});

		// On form load, we also trigger the processing fee right away, to
		//  make sure the initial state of the form shows it
		_showProcessingFee(config);

	}
}

function _showProcessingFee(config)
{
	let tenant_bill_state = '';
	if (getStateByType('billing'))
	{
		tenant_bill_state = getStateByType('billing');
	}
	else
	{
		tenant_bill_state = getStateByType('credit');
	}

	let paymethod = 'crd';
	if ($('input[name="paymethod"]:checked').val() == 'ach')
	{
		paymethod = 'ach';
	}

	let payload = {
		'paymethod': paymethod,
		'tenant_bill_state': tenant_bill_state,
		'tenant_currency_id': $('#customer_currency_id').val(),
	};

	$.post(config.url_showProcessingFee, payload, function( data ) {

		$('.handle_showProcessingFeeContainer').html(data);

	});
}

// This tells Webpack to export these functions so they are
module.exports = {
	// what you want to call it externally : what the function name is internally
	configure: configure
}

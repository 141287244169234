
function configure()
{

}

function validateRouting(field)
{
	var routingNumber = field.val();

	routingNumber = routingNumber.replace('[\D]', ''); //only digits

	var currency = field.data('currency_iso4217');
	if (!currency)
	{
		return 'Undetermined currency';
	}

	var cadCurrencyConst = 'CAD';
	var usdCurrencyConst = 'USD';
	let audCurrencyConst = 'AUD';

	let routingErr = '';

	if (currency === usdCurrencyConst)
	{
		if (routingNumber.length !== 9)
		{
			return 'ACH/eCheck bank routing numbers should be 9 digits long.';
		}

		//ach
		var checkSum = 0;
		var p1 = 0;
		var p2 = 0;
		var p3 = 0;
		for (var i = 0; i < routingNumber.length; i+= 3 )
		{
			//loop through routingNumber character by character
			p1 += ((+routingNumber[i]) * 3);
			p2 += ((+routingNumber[i+1]) * 7);
			p3 += ((+routingNumber[i+2]));
		}

		checkSum = p1 + p2 + p3;
		if ((checkSum !== 0) && ((checkSum % 10) === 0))
		{
			return true;
		}
		else
		{
			return 'This is not a valid ACH bank routing number.';
		}
	}
	else if (currency === cadCurrencyConst)
	{
		// Append the institution and transit numbers
		var institutionNumber = $("#ach_cad_inst").val().replace('[\D]', '');
		var transitNumber = $("#ach_cad_transit").val().replace('[\D]', '');

		if (!institutionNumber.length || !transitNumber.length)
		{
			// There is other validation that will catch this, and
			// if these are empty we don't want to trigger any validation
			// quite yet
			return true;
		}

		var routingNumber = '0' + institutionNumber + transitNumber;

		if (routingNumber.length != 9)
		{
			return 'Valid Canadian EFT routing numbers have either 8 digits, or 9 digits starting with a zero.';
		}

		// Do the best validation we can
		var firstDigit = String(routingNumber[1]);

		switch(firstDigit)
		{
			case "0":
			case "1":
			case "2":
			case "3":
			case "5":
			case "6":
			case "8":
				// OK!
				return true;
			default:
				return 'This is not a valid Canadian EFT transit number or institution code.';
		}

		return true;
	}
	else if (currency === audCurrencyConst)
	{

		if (routingNumber.length != 6 && routingNumber.length != 7)
		{
			return 'This is not a valid Australian BSB number';
		}

		return true;
	}

	// For any other currency, just assume this is correct
	return true;
}

// This tells Webpack to export these functions so they are
module.exports = {
	// what you want to call it externally : what the function name is internally
	validateRouting: validateRouting,
	configure: configure
}

var test = 'Testing variable';

function private_name() {

	// Grab a variable from the .env file
	var env_variable = process.env.EXAMPLE_URL;

	console.log('Webpack env build: ' + env_variable);

}

// This can be called from a class validate[funcCall[ChargeOver.Example.myFunction]] in the template
function myFunction() {
	// You must access the variable by calling the full namespace
	alert(ChargeOver.Example.test)
}

// This tells Webpack to export these functions so they are
module.exports = {

	// what you want to call it externally : what the function name is internally
	public_name: private_name
}
